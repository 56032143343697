body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.MuiBottomNavigationAction-root.Mui-selected {
  color: white !important;
}
:root {
  --primary-color: #cf171f;
  --primary-color-dark: #ff202c;
  --secondary-color: #2e2c2c;
}
.spacing {
  flex: 1;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #c5c5c5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2e2c2c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cf171f;
}
