@media (max-width: 560px) {
  .topHeader {
    flex-direction: column;
  }
  .topHeader {
    font-size: 9px !important;
    .fb {
      width: 7px !important;
      height: 15px !important;
    }
    div img {
      width: 12px !important;
    }
  }
  .logoContainer {
    padding: 0.2rem 6rem 0rem !important;
    img {
      width: 173px !important;
    }
  }
}
.topHeader {
  display: flex;
  font-size: 14px;
  color: #282828;
  padding: 0.5rem 3rem;
  border-bottom: 2px solid #e6e6e6;
  text-align: center;
  .fb {
    width: 9px !important;
    height: 19px;
  }
  div img {
    width: 15px;
    margin: 2px 5px 6px 5px;
  }
}
.fb:hover {
  cursor: pointer;
  transform: scale(1.5, 1.5);
}
.logoContainer {
  padding: 0.2rem 5rem 0;
  display: flex;
  justify-content: left;
  align-items: center;
  a,
  a:hover {
    text-decoration: none;
  }
  img {
    width: 240px;
    height: auto;
  }
}
.navcontainer {
  width: 100%;
}
.navMenu {
  background-color: #2e2c2c;
  padding: 0 5rem;
  ul {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: flex-end;
    list-style: none;
    li {
      margin: 0 0.8rem;
      padding: 0.4rem 0 0.1rem 0;
      cursor: pointer;
      a {
        text-decoration: none;
        font-size: 18px;
        color: white;
        &:hover,
        &:active {
          border-bottom: 0.3rem solid #cf171f;
          padding-bottom: 0;
        }
      }
    }
  }
}
a.active {
  border-bottom: 0.3rem solid #cf171f;
  padding-bottom: 0;
}
.news-slider {
  display: flex;
  background-color: #cf171f;
  padding: 0rem 2rem;
  font-size: 16px;
  span {
    width: 10rem;
    font-size: 19px;
    font-style: italic;
    margin: 0;
    a {
      color: white;
      text-decoration: none;
    }
    label {
      margin: 0;
      cursor: pointer;
    }
    img {
      height: 25px;
      margin: 0 6px 10px 0;
    }
  }
}
.sticky-inner-wrapper {
  z-index: 200;
}
marquee {
  width: 100%;
  padding-top: 5px;
  color: white;
}
.burger-btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
  .line {
    display: none;
    width: 27px;
    height: 2px;
    margin: 2.5px;
    background-color: white;
  }
}
@media (max-width: 768px) {
  label {
    font-size: 14px !important;
    img {
      height: 18px !important;
    }
  }
  .ebanking-btn {
    display: none !important;
  }
  .topHeader {
    flex-direction: column;
    align-items: center;
    font-size: 13px;
  }
  .burger-btn .line {
    display: block;
  }
  .navMenu {
    padding: 0.5rem 3rem !important;
    ul {
      display: none;
    }
  }
}

.ebanking-btn {
  width: 155px;
  height: 47px;
  padding: 0.5rem 0.5rem 0.5rem;
  font-weight: bolder !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #cf171f;
  color: white;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  img {
    height: 25px;
    width: 28px;
    margin-right: 4px;
  }
}

.primary-btn {
  background-color: #cf171f !important;
  color: white !important;
  position: relative;

  &:hover {
    background-color: #ff202c !important;
  }
}

.bankingHoursContainer {
  cursor: pointer;
  .bankingHours {
    -webkit-transition: 0.4s ease-in-out opacity;
    -moz-transition: 0.4s ease-in-out opacity;
    -o-transition: 0.4s ease-in-out opacity;
    transition: 0.4s ease-in-out opacity;
    position: absolute;
    left: 53px;
    top: 44px;
    background-color: #2e2c2c;
    color: white;
    max-width: 303px;
    z-index: 100000;
    padding: 0.5rem 1.5rem;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        text-align: left;
      }
    }
  }
}
