.spaceContainer {
  margin: 5rem;
}
.introductionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .introductionTitle {
    color: #cf171f;
    // text-transform: uppercase;
    margin-bottom: 2rem;
  }
  // .company-description {
  //   border: 1px solid black;
  //   border-top-left-radius: 312px;
  //   border-top-right-radius: 312px;
  //   text-overflow: clip;
  // }
}

.introWrapper {
  width: 100%;
}
.verticalMarginIntro {
  margin: 5rem 0;
}

@media (max-width: 960px) {
  .verticalMarginIntro {
    margin: 3rem 0;
  }
}

@media (max-width: 600px) {
  .verticalMarginIntro {
    margin: 2.5rem 0;
  }
  .MuiTypography-h3 {
    font-size: 2rem;
  }
}

@media (max-width: 599px) {
  .verticalMarginIntro .MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
    width: 100%;
  }
}

.view-count {
  width: 100%;
  i {
    font-size: 27px !important;
    text-decoration: underline;
    color: #cf171f;
    margin-right: 16px;
  }
  span {
    background-color: white;
    color: black;
    padding: 3px 6.5px;
    margin-right: 12px;
  }
  h1,
  h2 {
    margin: 0 !important;
  }
}
