.sidebar {
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 10000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  ul {
    margin-top: 5rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    li {
      margin: 0.5rem 0;
      a {
        color: #cf171f;
        text-decoration: none;
        font-size: 18px;
        cursor: pointer;
        &:hover,
        &:active {
          border-bottom: 0.4rem solid #cf171f;
          padding-bottom: 0;
        }
      }
    }
  }
}

.sidebar.open {
  transform: translateX(0);
}
