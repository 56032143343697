footer {
  background-color: #2e2c2c;
  .footer {
    width: 100% !important;
    width: 500px;
    color: white;
    padding: 5rem 0 2rem;
    label {
      font-size: 26px !important;
      font-weight: bold;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        padding: 0.5rem 0;
        a {
          color: white;
          text-decoration: none;
        }
        img {
          width: 35px;
          padding: 0 8px;
        }
        p {
          margin: 0 0 0 36px;
        }
      }
    }
  }
  table,
  th,
  td {
    border: 4px solid #cf171f;
    padding: 0px 4px;
    a {
      color: white;
      &:hover,
      &:active {
        color: white;
      }
    }
  }
  th {
    text-align: center !important;
  }
}

.clickable {
  i {
    font-size: 1.2rem;
    margin-right: 0.5rem;
    color: #cf171f;
  }
  &:hover {
    color: #cf171f;
    transform: scale(1.09);
    cursor: pointer;
  }
  a:hover {
    color: #cf171f;
    transform: scale(2);
  }
}

.clickable.active {
  color: #cf171f;
  transform: scale(1.09);
  cursor: pointer;
  a {
    color: #cf171f;
    transform: scale(2);
  }
}

.footer-links {
  a {
    color: white;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  a.active {
    li {
      color: #cf171f;
      transform: scale(1.09);
    }
  }
}

.copyright {
  width: 100% !important;
  background-color: black;
  width: 100px;
  padding: 0.8rem 0;
  text-align: center;
  color: white;
}
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.fbLike {
  display: flex;
  justify-content: center;
  align-items: center;
}

.calc {
  display: flex;
  span {
    flex: 50%;
  }
  input {
    height: 28px;
    flex: 50%;
    &:focus {
      outline: none;
    }
  }
  input[type="button"] {
    background-color: #cf171f;
    color: white;
    float: left;
  }
}
.h5 {
  color: #cf171f;
  font-size: 0.9rem;
  font-weight: 500;
  span {
    font-size: 1rem;
    font-weight: 400;
  }
}
